<template>
  <div style="width:100%;height:100vh;">
    <transition name="slide-fade">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/* .fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-to, .fade-leave {
  opacity: 1;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
} */
/* .slide-fade-enter-active {
  transition: all .3s ease;
} */
.slide-fade-leave-active {
  transition: .3s;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(1080px);
  opacity: 0;
}

/* .download-button {
  height: 445px;
  left: 238px;
  position: absolute;
  top: 860px;
} */


:root {
  --camouflage: #3f3913;
  --dove-gray: #707070;
  --eerie-black: #1a211c;
  --gargoyle-gas: #ffe54d;
  --link-water: #dbe2f7;
  --perano: #a5b7eb;
  --selago: #edf1fb;
  --torea-bay: #173799;
  --torea-bay-2: #17379980;
  --white: #ffffff;
  --wild-sand: #f4f5f1;

  --font-size-l: 34px;
  --font-size-m: 20px;
  --font-size-xl: 36px;
  --font-size-xxl: 40px;
  --font-size-xxxl: 72px;
  --font-size-xxxxl: 75px;
  --font-size-xxxxxl: 148px;

  --font-family-cormorant_garamond: "Cormorant Garamond", Helvetica;
  --font-family-cormorantgaramond-mediumitalic: "CormorantGaramond-MediumItalic",
    Helvetica;
  --font-family-cormorantgaramond-regular: "CormorantGaramond-Regular",
    Helvetica;
  --font-family-fontawesome: "FontAwesome", Helvetica;
  --font-family-raleway: "Raleway", Helvetica;
}
.raleway-normal-torea-bay-20px {
  color: var(--torea-bay);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.raleway-black-selago-40px {
  color: var(--selago);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 900;
}

.raleway-light-eerie-black-36px {
  color: var(--eerie-black);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 300;
}

.raleway-normal-perano-20px {
  color: var(--perano);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.cormorantgaramond-normal-selago-72px {
  color: var(--selago);
  font-family: var(--font-family-cormorant_garamond);
  font-size: var(--font-size-xxxl);
  font-style: italic;
  font-weight: 400;
}

.raleway-normal-dove-gray-36px {
  color: var(--dove-gray);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.raleway-normal-torea-bay-36px {
  color: var(--torea-bay);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.fontawesome-normal-gargoyle-gas-300px {
  color: var(--gargoyle-gas);
  font-family: var(--font-family-fontawesome);
  font-size: 300px;
  font-style: normal;
}

.raleway-black-torea-bay-40px {
  color: var(--torea-bay);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 900;
}
.raleway-black-torea-bay-40px-xxxl {
  color: var(--torea-bay);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 900;
}

.raleway-black-wild-sand-38px {
  color: var(--wild-sand);
  font-family: var(--font-family-raleway);
  font-size: 38px;
  font-style: normal;
  font-weight: 900;
}

.raleway-normal-white-34px {
  color: var(--white);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.cormorantgaramond-normal-torea-bay-75px {
  color: var(--torea-bay);
  font-family: var(--font-family-cormorant_garamond);
  font-size: var(--font-size-xxxxl);
  font-style: italic;
  font-weight: 400;
}

.border-4px-perano {
  border: 4px solid var(--perano);
}
</style>