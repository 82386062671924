import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/preview',
    name: 'Preview',
    component: () => import('@/views/Preview.vue')
  },
  {
    path: '/prescan',
    name: 'Prescan',
    component: () => import('@/views/Prescan.vue')
  },
  {
    path: '/prefile',
    name: 'Prefile',
    component: () => import('@/views/Prefile.vue')
  },
  {
    path: '/file',
    name: 'File',
    component: () => import('@/views/File.vue')
  },
  {
    path: '/scan',
    name: 'Scan',
    component: () => import('@/views/Scan.vue')
  },
  {
    path: '/result1',
    name: 'ResultDroid',
    component: () => import('@/views/ResultAndroid.vue')
  },
  {
    path: '/result2',
    name: 'ResultWin',
    component: () => import('@/views/ResultWindows.vue')
  },
  {
    path: '/result3',
    name: 'ResultIos',
    component: () => import('@/views/ResultIos.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router