import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api.js'
import router from '@/router'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    data: {}
  },
  getters: {
    passdata: state => {
      return state.data ? state.data : {};
    }
  },
  mutations: {
    setPassData: (state, response) => {
      state.data = response;
    },
  },
  actions: {
    LOAD_PASS_DATA: function (context, data) {
      return new Promise((resolve, reject) => {
        api
          .sendCodeString(data)
          .then((response) => {
            if (!response.file) throw {
              name: "Wallet not created"
            };
            context.commit("setPassData", response);
            if (api.isApple()) router.push("/result3");
            else if (api.isAndroid()) router.push("/result1");
            else if (api.isWindows()) router.push("/result2");
            else router.push("/result1");
            resolve();
          }).catch(error => {
            reject(error);
          });
      })
    },
  },
  modules: {}
})