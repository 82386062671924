import axios from "axios";
const API = process.env.NODE_ENV == "development" ? "http://192.168.112.114:4567/vcapi" : "https://service.elektroniskaskartes.lv/vcapi";

const vcapi = axios.create({
    withCredentials: true,
    baseURL: API,
});
const api = {
    api: API,
    link(l) {
        return API + l;
    },
    sendFile(file) {
        return this.apiMultipartPostRequest("/vc/v1/wp/img/covid", null, file);
    },
    sendBase64File(file) {
        return this.apiPostRequest("/vc/v1/wp/pdf/covid", {
            base64: file
        });
    },
    sendPlasticOrder(data){
        return this.apiPostRequest("/placeOrder", data);
    },
    sendCodeString(code) {
        return this.apiPostRequest("/vc/v1/wp/str/covid", {
            code: code
        });
    },
    apiPostRequest(uri, data, pars) {
        if (typeof pars == "undefined" || pars == null) pars = {};
        if (typeof data == "undefined") data = null;
        pars.prevcache = new Date().getTime();
        return new Promise((resolve, reject) => {
            vcapi.post(uri, data, {
                params: pars,
                withCredentials: true,
            }).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        })
    },
    apiMultipartPostRequest(uri, data, file, pars) {
        if (typeof pars == "undefined" || pars == null) pars = {};
        if (typeof data == "undefined") data = null;
        let formdata = new FormData();
        formdata.append("data", JSON.stringify(data));
        formdata.append("file", file, file.name);
        return new Promise((resolve, reject) => {
            vcapi.post(uri, formdata, {
                params: pars,
                withCredentials: true,
            }).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        })
    },
    apiGetRequest(uri, pars) {
        if (typeof pars == "undefined" || pars == null) pars = {};
        pars.prevcache = new Date().getTime();
        return new Promise((resolve, reject) => {
            vcapi.get(uri, {
                params: pars
            }).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        })
    },
    setVm(vm) {
        this.$vm = vm;
    },
    getSavedLocaleAsStr() {
        return localStorage.getItem("lang");
    },
    setLocale(v) {
        localStorage.setItem("lang", v);
        this.$vm.$i18n.locale = v;
    },
    isApple() {
        if (navigator.appVersion.indexOf("Mac OS") != -1) return true;
        return false;
    },
    isAndroid() {
        if (navigator.appVersion.indexOf("Android") != -1) return true;
        return false;
    },
    isWindows() {
        if (navigator.appVersion.indexOf("Win") != -1) return true;
        return false;
    },
}
export default api;