<template>
  <div class="component-1-1">
    <a href="https://www.idcskartes.lv">
      <div class="overlap-group1">
        <div :class="'rectangle-30 back-color-'+backType"></div>
        <div :class="'text-9 '+textClass">
          <i18n path="global.addr" />
        </div>
        <div :class="'text-10 '+textClass">
          <i18n path="global.mail" />
          <i18n path="global.phone" style="margin-left: 20px;" />
        </div>
        <div :class="'copyright '+textClass">
          <i18n path="global.copy" />
        </div>
        <img class="line-1" src="~@/assets/line-1@1x.png" />
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    backType: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    textClass() {
      return this.backType == 1
        ? "raleway-normal-torea-bay-20px"
        : "raleway-normal-perano-20px";
    },
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: red;
  color: white;
  text-align: center;
}
.component-1-1 {
  width: 100%;
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  overflow: hidden;
  justify-content: center;
/*  width: 1080px;*/
}

.overlap-group2 {
  height: 1920px;
  margin-left: -1px;
  position: relative;
  width: 1082px;
}

.overlap-group {
  height: 1920px;
  position: relative;
  width: 1081px;
}

.rectangle-26 {
  height: 144px;
  margin-left: 18px;
  margin-top: 66px;
  object-fit: cover;
  width: 144px;
}

.text {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 137px;
  min-height: 1643px;
  position: absolute;
  top: 168px;
  width: 810px;
}

.component-1-1 {
  align-items: flex-end;
  display: flex;
  height: 118px;
  /* left: 1px; */
  min-width: 1080px;
  position: absolute;
  top: 1802px;
}

.overlap-group1 {
  height: 120px;
  margin-bottom: -2px;
  position: relative;
  width: 1080px;
}

.back-color-1 {
  background-color: var(--link-water);
}
.back-color-2 {
  background-color: var(--torea-bay);
}
.rectangle-30 {
  /*  background-color: var(--link-water);*/
  height: 118px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1080px;
}

.text-9 {
  left: 184px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 13px;
  white-space: nowrap;
}

.text-10 {
  left: 609px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 13px;
  white-space: nowrap;
}

.copyright {
  left: 447px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 56px;
  white-space: nowrap;
}

.line-1 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1080px;
}
</style>