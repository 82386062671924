<template>
  <div v-show="value" class="modal">
    <div class="modal-content">
      <div class="modal-header">
        <div class="header-slot">
          <slot name="header" />
        </div>
        <CloseIcon :size="48" fillColor="white" decorative @click="($emit('close'),$emit('input',false))"></CloseIcon>
      </div>
      <div class="modal-body">
        <slot />
      </div>
      <div class="modal-footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from "vue-material-design-icons/CloseCircle.vue";

export default {
  props: ["value"],
  components: { CloseIcon },
};
</script>

<style scoped>
.modal {
  padding: 10px;
  font-size: 48px;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  text-align: center;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.modal-content {
  margin-left: 5%;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 20px;
  background-color: var(--link-water);
  padding: 0;
  border: 1px solid var(--torea-bay);
  width: 90%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;*/
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.modal-header {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  border-radius: 20px 20px 0 0;
  padding: 30px;
  background-color: var(--perano);
  color: white;
}

.modal-footer {
  padding: 40px;
}
.modal-body {
  padding: 40px;
}
.header-slot {
  width: 100%;
}
</style>