<template>
  <div class="container-center-horizontal">
    <div class="home screen">
      <div class="overlap-group2">
        <router-link to="/preview">
          <div class="vertical-stories">
            <div class="stories-3">
              <div class="overlap-group">
                <div class="background"></div>
                <div class="graphic-elements">
                  <img class="rectangle-26" src="@/assets/rectangle-26@1x.png" />
                </div>
                <div class="your-image-here custom-radius">
                  <div class="slideshow-container custom-radius">
                    <div class="mySlides fade">
                      <img src="@/assets/slide01.png" />
                    </div>
                    <div class="mySlides fade">
                      <img src="@/assets/slide02.png" />
                    </div>
                    <div class="mySlides fade">
                      <img src="@/assets/slide03.png" />
                    </div>
                    <div class="mySlides fade">
                      <img src="@/assets/slide04.png" />
                    </div>
                  </div>
                </div>
                <div class="text">
                  <div class="text-7 raleway-black-selago-40px">
                    <i18n path="global.title" />
                  </div>
                  <div class="text-8">
                    <div>
                      <i18n path="global.line11" />
                    </div>
                    <div>
                      <i18n path="global.line12" />
                    </div>
                  </div>
                  <div class="group-43"></div>
                  <div class="text-6 raleway-light-eerie-black-36px">
                    <div>
                      <i18n path="home.line21" />
                    </div>
                    <div>
                      <i18n path="home.line22" />
                    </div>
                    <div>
                      <i18n path="home.line23" />
                    </div>
                    <div>
                      <i18n path="home.line24" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </router-link>
        <router-link to="/preview">
          <img class="path-2 animate-enter" show-on-scroll src="@/assets/path-2@1x.png" />
        </router-link>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
let caruselHomeIndex = 0;
let toHomeCarusel = null;

export default {
  name: "Home",
  components: { Footer },
  beforeRouteLeave(to, from, next) {
    clearTimeout(toHomeCarusel);
    next();
  },
  mounted() {
    caruselHomeIndex = 0;
    carousel();
    function carousel() {
      try {
        caruselHomeIndex++;
        if (caruselHomeIndex > 4) {
          caruselHomeIndex = 1;
        }
        document.getElementsByClassName("mySlides").forEach((e, ind) => {
          e.style.display =
            ind == caruselHomeIndex-1 || ind == 4 ? "block" : "none";
        });
        toHomeCarusel = setTimeout(carousel, 3000);
      } catch (error) {
        caruselHomeIndex = -1;
      }
    }
  },
};
</script>

<style scoped>
/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  height: 984px;
  position: relative;
  margin: auto;
  text-align: center;
}
.mySlides {
  display: none;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 3s;
  animation-name: fade;
  animation-duration: 3s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

/* screen - home */
.home {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  overflow: hidden;
  width: 1080px;
}

.home .overlap-group2 {
  height: 1920px;
  margin-left: -1px;
  position: relative;
  width: 1082px;
}

.home .vertical-stories {
  cursor: pointer;
  display: flex;
  height: 1920px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1081px;
}

.home .stories-3 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  min-width: 1081px;
}

.home .overlap-group {
  height: 1920px;
  position: relative;
  width: 1081px;
}

.home .background {
  background-color: var(--link-water);
  height: 1920px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1080px;
}

.home .graphic-elements {
  background-color: var(--torea-bay);
  display: flex;
  height: 1323px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 1080px;
}

.home .rectangle-26 {
  height: 144px;
  margin-left: 18px;
  margin-top: 66px;
  object-fit: cover;
  width: 144px;
}

.home .your-image-here {
  background-image: url(~@/assets/group-37@1x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 984px;
  left: 113px;
  position: absolute;
  top: 446px;
  width: 856px;
}

.home .text {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 137px;
  min-height: 1643px;
  position: absolute;
  top: 168px;
  width: 810px;
}

.home .text-7 {
  letter-spacing: 12px;
  line-height: 64px;
  margin-right: 11.55px;
  margin-top: -8px;
  min-height: 64px;
  min-width: 718px;
  text-align: center;
  white-space: nowrap;
}

.home .text-8 {
  color: var(--selago);
  font-family: var(--font-family-cormorant_garamond);
  font-size: var(--font-size-xxxxl);
  font-style: italic;
  letter-spacing: 0;
  line-height: 64px;
  margin-left: 2.16px;
  margin-top: 54px;
  min-height: 157px;
  min-width: 618px;
  text-align: center;
  white-space: nowrap;
}

.home .group-43 {
  /*background-image: url(~@/assets/group-39@1x.png);*/
  background-position: 50% 50%;
  background-size: cover;
  height: 828px;
  margin-right: 2px;
  margin-top: 167px;
  width: 768px;
}

.home .text-6 {
  height: 334px;
  letter-spacing: 2.7px;
  line-height: 56px;
  margin-top: 38px;
  text-align: center;
  width: 810px;
}

.home .component-1-1 {
  align-items: flex-end;
  display: flex;
  height: 118px;
  left: 1px;
  min-width: 1080px;
  position: absolute;
  top: 1802px;
}

.home .overlap-group1 {
  height: 120px;
  margin-bottom: -2px;
  position: relative;
  width: 1080px;
}

.home .rectangle-30 {
  background-color: var(--link-water);
  height: 118px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1080px;
}

.home .text-9 {
  left: 184px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 13px;
  white-space: nowrap;
}

.home .text-10 {
  left: 609px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 13px;
  white-space: nowrap;
}

.home .copyright {
  left: 447px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 56px;
  white-space: nowrap;
}

.home .line-1 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1080px;
}

.home .path-2 {
  cursor: pointer;
  display: block;
  height: 67px;
  left: 998px;
  object-fit: cover;
  position: absolute;
  top: 927px;
  width: 63px;
  opacity: 0;
  transform: translate(-25px, 0);
}

.home .path-2.animate-enter {
  animation: animate-enter-frames 0.2s ease 0s 1 normal forwards;
  display: block;
  opacity: 0;
  transform: translate(-25px, 0);
}

@keyframes animate-enter-frames {
  from {
    opacity: 0;
    transform: translate(-25px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
</style>