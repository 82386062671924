<template>
  <MyDialog v-model="d_open" @close="close">
    <template #header>
      <h2 class="dialog-hdr">
        <i18n path="error.title" />
      </h2>
    </template>
    <template>
      <div class="body-container">
        <ErrorIcon :size="100" fillColor="red" decorative />
        <p class="raleway-black-torea-bay-40px-xxxl">{{errorText}}</p>
      </div>
    </template>
    <template #footer>
      <i18n path="error.detail" class="error-footer">
        <template #code>{{errorCode}}</template>
      </i18n>
    </template>
  </MyDialog>
</template>

<script>
import MyDialog from "@/components/Dialog.vue";
import ErrorIcon from "vue-material-design-icons/AlertCircleOutline.vue";

export default {
  name: "ErrorDialog",
  components: { MyDialog, ErrorIcon },
  props: ["value", "errorText", "errorCode"],
  data() {
    return {};
  },
  computed: {
    d_open: {
      get() {
        return this.value;
      },
      set() {},
    },
  },
  methods: {
    close() {
      this.$emit("close");
      this.$emit("input", false);
    },
  },
};
</script>

<style scoped>
.error-footer {
  font-size: x-large;
}
.body-container {
  display: flex;
}
</style>